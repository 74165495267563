

























import { Component, Vue } from 'vue-property-decorator';
import GithubButton from 'vue-github-button';

@Component({
  components: { GithubButton },
})
export default class About extends Vue {}
